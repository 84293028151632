// src/pages/EditUser.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUser, updateUser } from "../services/userService";

const EditUser = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [wallBackground, setWallBackground] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState("");
  const [wallBackgroundPreview, setWallBackgroundPreview] = useState("");
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const user = await getUser(userId);
        setUserData(user);

        // Set the preview URLs if they exist
        if (user.profilePictureUrl) {
          setProfilePicturePreview(user.profilePictureUrl);
        }
        if (user.wallBackgroundUrl) {
          setWallBackgroundPreview(user.wallBackgroundUrl);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setError("Failed to fetch user data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleArrayChange = (e, field) => {
    const values = e.target.value.split(",").map((item) => item.trim());
    setUserData((prevData) => ({
      ...prevData,
      [field]: values,
    }));
  };

  const handleFileChange = (e, setFile, setPreview) => {
    const file = e.target.files[0];
    setFile(file);

    // Generate a preview URL for the file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setPreview(""); // Reset the preview if no file is selected
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await updateUser(
        userId,
        userData,
        profilePicture,
        wallBackground
      );
      console.log("User updated successfully:", updatedUser);
      navigate("/users");
    } catch (error) {
      console.error("Error updating user:", error);
      setError("Failed to update user. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!userData) return null;

  return (
    <div className="edit-user-container">
      <h2>Edit Profile</h2>
      <form onSubmit={handleSubmit}>
        {/* Basic Information Fields */}
        <div className="form-section">
          <h3>Basic Information</h3>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              id="name"
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              id="username"
              name="username"
              value={userData.username}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              id="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              id="phoneNumber"
              name="phoneNumber"
              value={userData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="dateOfBirth">Date of Birth:</label>
            <input
              id="dateOfBirth"
              name="dateOfBirth"
              type="date"
              value={
                userData.dateOfBirth ? userData.dateOfBirth.split("T")[0] : ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role:</label>
            <input
              id="role"
              name="role"
              value={userData.role}
              onChange={handleChange}
            />
          </div>

          <div className="form-section">
            <h3>Profile Information</h3>
            <div className="form-group">
              <label htmlFor="profilePicture">Profile Picture:</label>
              {profilePicturePreview && (
                <div>
                  <img
                    src={profilePicturePreview}
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              )}
              <input
                type="file"
                id="profilePicture"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setProfilePicture,
                    setProfilePicturePreview
                  )
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="wallBackground">Wall Background:</label>
              {wallBackgroundPreview && (
                <div>
                  <img
                    src={wallBackgroundPreview}
                    alt="Wall Background Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              )}
              <input
                type="file"
                id="wallBackground"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setWallBackground,
                    setWallBackgroundPreview
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="form-section">
          <h3>Profile Information</h3>
          <div className="form-group">
            <label htmlFor="profilePictureUrl">Profile Picture URL:</label>
            <input
              id="profilePictureUrl"
              name="profilePictureUrl"
              value={userData.profilePictureUrl || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="wallBackgroundUrl">Wall Background URL:</label>
            <input
              id="wallBackgroundUrl"
              name="wallBackgroundUrl"
              value={userData.wallBackgroundUrl || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="school">School:</label>
            <input
              id="school"
              name="school"
              value={userData.school || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="batch">Batch:</label>
            <input
              id="batch"
              name="batch"
              value={userData.batch || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="currentPosition">Current Position:</label>
            <input
              id="currentPosition"
              name="currentPosition"
              value={userData.currentPosition || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="currentLocation">Current Location:</label>
            <input
              id="currentLocation"
              name="currentLocation"
              value={userData.currentLocation || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="linkedInProfile">LinkedIn Profile:</label>
            <input
              id="linkedInProfile"
              name="linkedInProfile"
              value={userData.linkedInProfile || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="bio">Bio:</label>
            <textarea
              id="bio"
              name="bio"
              value={userData.bio || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-section">
          <h3>School Information</h3>
          <div className="form-group">
            <label htmlFor="schoolId">School IDs:</label>
            <input
              id="schoolId"
              name="schoolId"
              value={userData.schoolId ? userData.schoolId.join(", ") : ""}
              onChange={(e) => handleArrayChange(e, "schoolId")}
            />
          </div>
        </div>

        <div className="form-section">
          <h3>Preferences</h3>
          <div className="form-group">
            <label htmlFor="preferredCategories">Preferred Categories:</label>
            <input
              id="preferredCategories"
              name="preferredCategories"
              value={
                userData.preferredCategories
                  ? userData.preferredCategories.join(", ")
                  : ""
              }
              onChange={(e) => handleArrayChange(e, "preferredCategories")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="preferredTags">Preferred Tags:</label>
            <input
              id="preferredTags"
              name="preferredTags"
              value={
                userData.preferredTags ? userData.preferredTags.join(", ") : ""
              }
              onChange={(e) => handleArrayChange(e, "preferredTags")}
            />
          </div>
        </div>

        <div className="form-section">
          <h3>Connections</h3>
          <div className="form-group">
            <label htmlFor="connectionUserIds">Connected Users:</label>
            <input
              id="connectionUserIds"
              name="connectionUserIds"
              value={
                userData.connectionUserIds
                  ? userData.connectionUserIds.join(", ")
                  : ""
              }
              onChange={(e) => handleArrayChange(e, "connectionUserIds")}
            />
          </div>
        </div>

        <div className="form-section">
          <h3>Activity Summary</h3>
          <div className="form-group">
            <label htmlFor="donationAmount">Donation Amount:</label>
            <input
              type="number"
              id="donationAmount"
              name="donationAmount"
              value={userData.donationAmount || 0}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="volunteerActivityCount">
              Volunteer Activity Count:
            </label>
            <input
              type="number"
              id="volunteerActivityCount"
              name="volunteerActivityCount"
              value={userData.volunteerActivityCount || 0}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="eventsAttendedCount">Events Attended Count:</label>
            <input
              type="number"
              id="eventsAttendedCount"
              name="eventsAttendedCount"
              value={userData.eventsAttendedCount || 0}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="mentorshipCount">Mentorship Count:</label>
            <input
              type="number"
              id="mentorshipCount"
              name="mentorshipCount"
              value={userData.mentorshipCount || 0}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="connectionCount">Connection Count:</label>
            <input
              type="number"
              id="connectionCount"
              name="connectionCount"
              value={userData.connectionCount || 0}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="invitationCount">Invitation Count:</label>
            <input
              type="number"
              id="invitationCount"
              name="invitationCount"
              value={userData.invitationCount || 0}
              readOnly
            />
          </div>
        </div>

        <button type="submit">Update Profile</button>
      </form>
    </div>
  );
};

export default EditUser;
