import React from "react";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";

const FinancialDetails = ({ profile, isEditable }) => {
  const handleChange = (event) => {
    console.log("Handle change logic here...");
  };

  const handleSaveFinancialDetails = () => {
    // Save logic for Financial Details tab
    console.log("Saving Financial Details...", profile);
    // API call for saving financial details
  };

  return (
    <Box>
      <Typography variant="h6">Financial Information</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField label="Fee Type" fullWidth value={profile.feeType} />
        </Grid>
        {/* Other financial fields go here */}
      </Grid>

      {isEditable && (
        <Box sx={{ textAlign: "right", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveFinancialDetails}
          >
            Save Financial Details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FinancialDetails;
