import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import SectionHeader from './SectionHeader';
import FormField from './../FormField';

const EducationalDetails = ({ profile, isEditable, handleChange }) => {
  const [resultStatus, setResultStatus] = useState(profile.resultStatus);
  const [entranceStatus, setEntranceStatus] = useState(profile.entranceStatus);
  const [applicationStatus, setApplicationStatus] = useState(profile.applicationStatus);
  const [admissionDetails, setAdmissionDetails] = useState(profile.admissionDetails);

  // Handle Result Status Condition
  const handleResultStatusChange = (event) => {
    setResultStatus(event.target.value);
    handleChange(event);
  };

  // Handle Entrance Exam Status Condition
  const handleEntranceStatusChange = (event) => {
    setEntranceStatus(event.target.value);
    handleChange(event);
  };

  // Handle Application Status Condition
  const handleApplicationStatusChange = (event) => {
    setApplicationStatus(event.target.value);
    handleChange(event);
  };

  // Handle Admission Details Condition
  const handleAdmissionDetailsChange = (event) => {
    setAdmissionDetails(event.target.value);
    handleChange(event);
  };

  const handleSaveEducationalDetails = () => {
    // Save logic for Educational Details tab
    console.log("Saving Educational Details...", profile);
    // API call for saving educational details
  };

  return (
    <Box>
      <SectionHeader title="Educational Information" />
      <Grid container spacing={3}>
        {/* Name of the Entrance Exam */}
        <Grid item xs={12} sm={6}>
          <FormField
            name="entranceExam"
            label="Name of the Entrance Exam"
            value={profile.entranceExam}
            isEditable={isEditable}
            handleChange={handleChange}
            type="select"
            options={['JEE Mains', 'NATA', 'NEET']}
          />
        </Grid>

        {/* Status of the Entrance Exam */}
        <Grid item xs={12} sm={6}>
          <FormField
            name="entranceStatus"
            label="Status of the Entrance Exam"
            value={entranceStatus}
            isEditable={isEditable}
            handleChange={handleEntranceStatusChange}
            type="select"
            options={['Applied - Waiting for the exam', 'Written - Waiting for results', 'Results released']}
          />
        </Grid>

        {/* Marks/ Rank Obtained - Conditionally displayed if entrance status is 'Results released' */}
        {entranceStatus === 'Results released' && (
          <>
            <Grid item xs={12} sm={6}>
              <FormField
                name="marksObtained"
                label="Marks/ Rank Obtained"
                value={profile.marksObtained}
                isEditable={isEditable}
                handleChange={handleChange}
                type="text" // Text field
              />
            </Grid>

            {/* Result Status */}
            <Grid item xs={12} sm={6}>
              <FormField
                name="resultStatus"
                label="Result Status"
                value={resultStatus}
                isEditable={isEditable}
                handleChange={handleResultStatusChange}
                type="select"
                options={['Qualified', 'Waitlisted', 'Not qualified']}
              />
            </Grid>
          </>
        )}

        {/* Colleges Applied Post Exam - Conditionally displayed if Result Status is 'Qualified' */}
        {resultStatus === 'Qualified' && (
          <>
            <Grid item xs={12} sm={6}>
              <FormField
                name="collegesApplied"
                label="Colleges/ Institutes Applied Post Exam"
                value={profile.collegesApplied}
                isEditable={isEditable}
                handleChange={handleChange}
                type="select"
                options={['IIT Madras', 'IIT Kharagpur']}
              />
            </Grid>

            {/* Course Applied */}
            <Grid item xs={12} sm={6}>
              <FormField
                name="courseApplied"
                label="Course Applied"
                value={profile.courseApplied}
                isEditable={isEditable}
                handleChange={handleChange}
                type="select"
                options={['Marine Technology', 'Plastic Technology']}
              />
            </Grid>

            {/* Application Status */}
            <Grid item xs={12} sm={6}>
              <FormField
                name="applicationStatus"
                label="Application Status"
                value={applicationStatus}
                isEditable={isEditable}
                handleChange={handleApplicationStatusChange}
                type="select"
                options={['Submitted', 'Pending', 'Accepted', 'Rejected']}
              />
            </Grid>

            {/* Admission Details - Conditionally displayed if Application Status is 'Accepted' */}
            {applicationStatus === 'Accepted' && (
              <Grid item xs={12} sm={6}>
                <FormField
                  name="admissionDetails"
                  label="Admission Details"
                  value={admissionDetails}
                  isEditable={isEditable}
                  handleChange={handleAdmissionDetailsChange}
                  type="select"
                  options={['Joined', 'Not Joined']}
                />
              </Grid>
            )}

            {/* Details for Not Joined - Conditionally displayed if Admission Details is 'Not Joined' */}
            {admissionDetails === 'Not Joined' && (
              <Grid item xs={12} sm={6}>
                <FormField
                  name="notJoinedReason"
                  label="Reason for Not Joining"
                  value={profile.notJoinedReason}
                  isEditable={isEditable}
                  handleChange={handleChange}
                  type="text" // Textbox for entering reasons
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      {isEditable && (
        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveEducationalDetails}
          >
            Save Educational Details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EducationalDetails;
