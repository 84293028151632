import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  IconButton, 
  Menu, 
  MenuItem, 
  Checkbox,
  Paper,
  Breadcrumbs,
  Link,
  Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';

const initialRows = [
  { id: 1, firstName: 'Ram Karthick', contact: '+91 88XXX XXXXX', gender: 'Male', year: '2024', country: 'India', state: 'Tamil Nadu', city: 'Chennai', institute: 'Anna University', qualification: 'B.E.', stream: 'Electronic', course: 'Electronic', status: 'Current Batch' },
  { id: 2, firstName: 'Devipriya', contact: '+91 98XXX XXXXX', gender: 'Female', year: '2024', country: 'India', state: 'Tamil Nadu', city: 'Salem', institute: 'Dr. M.G.R. Medical University', qualification: 'B.A.', stream: 'Literature', course: 'Literature', status: 'Current Batch' },
];

const allColumns = [
  { field: 'id', headerName: 'S.No.', width: 70 },
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'contact', headerName: 'Contact', width: 150 },
  { field: 'gender', headerName: 'Gender', width: 100 },
  { field: 'year', headerName: 'Year', width: 80 },
  { field: 'country', headerName: 'Country', width: 100 },
  { field: 'state', headerName: 'State', width: 120 },
  { field: 'city', headerName: 'City', width: 120 },
  { field: 'institute', headerName: 'Institute name', width: 200 },
  { field: 'qualification', headerName: 'Highest Qualification', width: 180 },
  { field: 'stream', headerName: 'Stream', width: 150 },
  { field: 'course', headerName: 'Course', width: 150 },
  { field: 'status', headerName: 'Current Status', width: 150 },
];

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const SelectionRankingPage = () => {
  const [rows, setRows] = useState(initialRows);
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(allColumns);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filteredRows = initialRows.filter((row) => 
      Object.values(row).some(val => 
        val.toString().toLowerCase().includes(value)
      )
    );
    setRows(filteredRows);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns(prev => 
      prev.find((col) => col.field === column.field)
        ? prev.filter((col) => col.field !== column.field)
        : [...prev, column]
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link color="inherit" href="/">
          Home
        </Link>
        <Link color="inherit" href="/student-management">
          Student Management
        </Link>
        <Typography color="textPrimary">Ranking Data</Typography>
      </Breadcrumbs>

      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="h1" color="primary">
            Selection Ranking Data
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search for anything..."
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconButton size="small">
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <StyledButton
              variant="outlined"
              color="primary"
              startIcon={<ShareIcon />}
            >
              Share the List
            </StyledButton>
            <StyledButton
              variant="outlined"
              color="primary"
              startIcon={<VisibilityIcon />}
              onClick={handleMenuClick}
            >
              Show / Hide Columns
            </StyledButton>
            <StyledButton
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
            >
              Filters
            </StyledButton>
          </Box>
          <Typography variant="body2" color="textSecondary">
            Showing 1-10 of 100
          </Typography>
        </Box>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {allColumns.map((column) => (
          <MenuItem key={column.field} onClick={() => handleColumnVisibilityChange(column)}>
            <Checkbox
              checked={visibleColumns.find((col) => col.field === column.field) !== undefined}
            />
            {column.headerName}
          </MenuItem>
        ))}
      </Menu>

      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={visibleColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#5E35B1', // Use theme primary color for header background
              color: 'black', // White text for contrast
              fontSize: '16px', // Increase font size for better visibility
              fontWeight: 'bold',
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default SelectionRankingPage;
