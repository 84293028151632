// LinkBlock.js
import React from 'react';
import { Box, TextField, Typography, CardMedia } from '@mui/material';

const LinkBlock = ({ block, updateBlock }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      linkContent: {
        ...block.linkContent,
        [name]: value,
      },
    });
  };

  return (
    <Box mt={2}>
      <TextField
        label="Link URL"
        name="url"
        value={block.linkContent?.url || ''}
        onChange={handleChange}
        placeholder="Link URL"
        fullWidth
      />
      <TextField
        label="Link Title"
        name="title"
        value={block.linkContent?.title || ''}
        onChange={handleChange}
        placeholder="Link Title"
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Link Description"
        name="description"
        value={block.linkContent?.description || ''}
        onChange={handleChange}
        placeholder="Link Description"
        multiline
        rows={4}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Thumbnail URL"
        name="thumbnailUrl"
        value={block.linkContent?.thumbnailUrl || ''}
        onChange={handleChange}
        placeholder="Thumbnail URL"
        fullWidth
        sx={{ mt: 2 }}
      />
      {block.linkContent?.thumbnailUrl && (
        <Box mt={2}>
          <Typography variant="subtitle1">Thumbnail Preview:</Typography>
          <CardMedia
            component="img"
            image={block.linkContent.thumbnailUrl}
            alt="Thumbnail Preview"
            sx={{ maxWidth: 200 }}
          />
        </Box>
      )}
    </Box>
  );
};

export default LinkBlock;
