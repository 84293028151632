// TextBlock.js
import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const TextBlock = ({ block, updateBlock }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      textContent: {
        ...block.textContent,
        [name]: value,
      },
    });
  };

  const handleStyleChange = (e) => {
    updateBlock({
      ...block,
      textContent: {
        ...block.textContent,
        styles: {
          ...block.textContent.styles,
          format: e.target.value,
        },
      },
    });
  };

  return (
    <Box mt={2}>
      <TextField
        name="text"
        label="Text Content"
        value={block.textContent?.text || ''}
        onChange={handleChange}
        placeholder="Enter text content"
        multiline
        rows={4}
        fullWidth
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Text Format</InputLabel>
        <Select
          value={block.textContent?.styles?.format || 'PLAIN'}
          label="Text Format"
          onChange={handleStyleChange}
        >
          <MenuItem value="PLAIN">Plain</MenuItem>
          <MenuItem value="BOLD">Bold</MenuItem>
          <MenuItem value="ITALIC">Italic</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TextBlock;
