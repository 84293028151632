// PostList.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFeed, deletePost } from '../services/postService';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [lastEvaluatedPk, setLastEvaluatedPk] = useState(null);
  const [lastEvaluatedSk, setLastEvaluatedSk] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const data = await getFeed(lastEvaluatedPk, lastEvaluatedSk);
      setPosts((prevPosts) => [...prevPosts, ...data.feedItems]);
      setLastEvaluatedPk(data.newLastEvaluatedPk);
      setLastEvaluatedSk(data.newLastEvaluatedSk);
    } catch (err) {
      setError('Failed to fetch posts. Please try again.');
      console.error('Fetch posts error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const id = postId.includes('#') ? postId.split('#')[1] : postId;
        await deletePost(id);
        setPosts(posts.filter((post) => post.postId !== postId));
      } catch (err) {
        setError('Failed to delete post. Please try again.');
        console.error('Delete post error:', err);
      }
    }
  };

  const handleEdit = (postId) => {
    const id = postId.includes('#') ? postId.split('#')[1] : postId;
    navigate(`/edit-post/${id}`);
  };

  if (loading && posts.length === 0)
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );

  if (error)
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Posts
        </Typography>

        <TableContainer component={Paper}>
          <Table aria-label="posts table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Post ID</strong></TableCell>
                <TableCell><strong>Title</strong></TableCell>
                <TableCell><strong>Author</strong></TableCell>
                <TableCell><strong>Created Date</strong></TableCell>
                <TableCell><strong>PK</strong></TableCell>
                <TableCell><strong>SK</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {posts.map((post) => (
                <TableRow key={post.postId}>
                  <TableCell>{post.postId}</TableCell>
                  <TableCell>{post.title}</TableCell>
                  <TableCell>{post.authorName}</TableCell>
                  <TableCell>
                    {new Date(post.SK || post.sk).toLocaleString()}
                  </TableCell>
                  <TableCell>{post.PK || post.pk}</TableCell>
                  <TableCell>{post.SK || post.sk}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleEdit(post.postId)}
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(post.postId)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {lastEvaluatedPk && lastEvaluatedSk && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button
              variant="contained"
              onClick={fetchPosts}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load More'}
            </Button>
          </Box>
        )}

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/create-post"
          >
            Create New Post
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PostList;
