import React from 'react';
import { Grid, Box } from '@mui/material';
import SectionHeader from './../SectionHeader';
import FormField from './../../FormField';

const StudentDocumentsUpload = ({ profile, isEditable, handleChange }) => {
  return (
    <Box>
      <SectionHeader title="Student Documents Upload" />
      <Grid container spacing={3}>
        {/* Aadhar Card */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="aadharCard"
            label="Aadhar Card"
            value={profile.aadharCard}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* Community Certificate */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="communityCertificate"
            label="Community Certificate"
            value={profile.communityCertificate}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* First Graduate Certificate */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="firstGraduateCertificate"
            label="First Graduate Certificate"
            value={profile.firstGraduateCertificate}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* PWD Certificate */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="pwdCertificate"
            label="PWD Certificate"
            value={profile.pwdCertificate}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* UDID Book */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="udidBook"
            label="UDID Book"
            value={profile.udidBook}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* Address Proof */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="addressProof"
            label="Address Proof"
            value={profile.addressProof}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* Nativity Certificate */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="nativityCertificate"
            label="Nativity Certificate"
            value={profile.nativityCertificate}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* Income Certificate */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="incomeCertificate"
            label="Income Certificate"
            value={profile.incomeCertificate}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* Transfer Certificate */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="transferCertificate"
            label="Transfer Certificate"
            value={profile.transferCertificate}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>

        {/* Marksheet */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="marksheet"
            label="Marksheet"
            value={profile.marksheet}
            isEditable={isEditable}
            handleChange={handleChange}
            type="file"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentDocumentsUpload;
