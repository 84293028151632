import React from 'react';
import { Grid, Box } from '@mui/material';
import SectionHeader from './../SectionHeader';
import FormField from './../../FormField';

const PersonalDetails = ({ profile, isEditable, handleChange }) => {
  return (
    <Box>
      <SectionHeader title="Personal Information" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormField
            name="name"
            label="Name"
            value={profile.name}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="date_of_birth"
            label="Date of Birth"
            value={profile.date_of_birth}
            isEditable={isEditable}
            handleChange={handleChange}
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="gender"
            label="Gender"
            value={profile.gender}
            isEditable={isEditable}
            handleChange={handleChange}
            type="select"
            options={['Male', 'Female', 'Other']}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="contact_number"
            label="Contact Number"
            value={profile.contact_number}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="alternate_contact_number"
            label="Alternate Contact Number"
            value={profile.alternate_contact_number}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="emergency_contact_number"
            label="Emergency Contact Number"
            value={profile.emergency_contact_number}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="aadhar_number"
            label="Aadhar Number"
            value={profile.aadhar_number}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="community"
            label="Community"
            value={profile.community}
            isEditable={isEditable}
            handleChange={handleChange}
            type="select"
            options={['General', 'OBC', 'SC', 'ST']}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="residential_address"
            label="Residential Address"
            value={profile.residential_address}
            isEditable={isEditable}
            handleChange={handleChange}
            type="textarea"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="permanent_address"
            label="Permanent Address"
            value={profile.permanent_address}
            isEditable={isEditable}
            handleChange={handleChange}
            type="textarea"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDetails;