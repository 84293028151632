import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

const COLORS = ['#673ab7', '#e91e63', '#ff9800'];

const ParticipationData = ({ data, showVisited = false }) => {
  if (!data) return null;

  const displayData = showVisited ? data.visitedStudents : data.totalStudents;
  const chartData = [
    { name: 'Boys', value: displayData.boys },
    { name: 'Girls', value: displayData.girls },
    { name: 'Transgender', value: displayData.transgender },
  ];

  const total = chartData.reduce((sum, item) => sum + item.value, 0);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" gutterBottom>
        {showVisited ? 'Total No. of Students Visited' : 'Students'}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <ResponsiveContainer width="90%" height={250}>  {/* Increased height, reduced width */}
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"  // Center X-axis
                cy="50%"  // Center Y-axis
                innerRadius={40}  // Slightly reduced size for more space
                outerRadius={65}  // Reduced width for better appearance
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = 20 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      x={x}
                      y={y}
                      fill={COLORS[index % COLORS.length]}
                      textAnchor={x > cx ? 'start' : 'end'}
                      dominantBaseline="central"
                      fontSize="12px"  // Slightly reduce font size for labels
                    >
                      {value}
                    </text>
                  );
                }}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend layout="horizontal" align="center" verticalAlign="bottom" /> {/* Adjust legend */}
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ParticipationData;
