import React from 'react';
import { Box, Button } from '@mui/material';
import PersonalDetails from './personalDetailsSections/PersonalDetails';
import ParentalDetails from './personalDetailsSections/ParentalDetails';
import GuardianDetails from './personalDetailsSections/GuardianDetails';
import StudentDocumentsUpload from './personalDetailsSections/StudentDocumentsUpload';

const PersonalDetailsTab = ({ profile, isEditable, handleChange, handleSave }) => {


  const handleSavePersonalDetails = () => {

    const personalData = {
      name: profile.name,
      date_of_birth: profile.date_of_birth,
      gender: profile.gender,
      contact_number: profile.contact_number,
      alternate_contact_number: profile.alternate_contact_number,
      emergency_contact_number: profile.emergency_contact_number,
      email: profile.email,
      aadhar_number: profile.aadhar_number,
      residential_address: profile.residential_address,
      permanent_address: profile.permanent_address,
      blood_group: profile.blood_group,
      community: profile.community,
      disability: profile.disability,

    };

    // Log to ensure all fields are captured before saving
    console.log('personalData:', personalData);

    // Call the handleSave function passed from the parent component
    handleSave(personalData);
};


  return (
    <Box>
      <PersonalDetails profile={profile} isEditable={isEditable} handleChange={handleChange} />
      <ParentalDetails profile={profile} isEditable={isEditable} handleChange={handleChange} />
      <GuardianDetails profile={profile} isEditable={isEditable} handleChange={handleChange} />
      <StudentDocumentsUpload profile={profile} isEditable={isEditable} handleChange={handleChange} />

      {isEditable && (
        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePersonalDetails}
          >
            Save Personal Details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PersonalDetailsTab;