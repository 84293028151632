import {nodeApi} from "./api";

export const listStudents = async (params) => {
  try {
    const response = await nodeApi.get('/api/students', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStudentById = async (emisNumber) => {

  try {
    const response = await nodeApi.get(`/api/students/${emisNumber}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createStudent = async (studentData) => {
  try {
    const response = await nodeApi.post('/api/students', studentData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStudent = async (emisNumber, studentData) => {
  try {
    const response = await nodeApi.put(`/api/students/${emisNumber}`, studentData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStudent = async (emisNumber) => {
  try {
    const response = await nodeApi.delete(`/api/students/${emisNumber}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
