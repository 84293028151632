// PollBlock.js
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from '@mui/material';

const PollBlock = ({ block, updateBlock }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      pollContent: {
        ...block.pollContent,
        [name]: value,
      },
    });
  };

  const addOption = () => {
    const newOption = {
      id: uuidv4(),
      text: '',
      votes: 0,
    };
    updateBlock({
      ...block,
      pollContent: {
        ...block.pollContent,
        options: [...(block.pollContent?.options || []), newOption],
      },
    });
  };

  const updateOption = (index, updatedOption) => {
    const updatedOptions = [...(block.pollContent?.options || [])];
    updatedOptions[index] = updatedOption;
    updateBlock({
      ...block,
      pollContent: {
        ...block.pollContent,
        options: updatedOptions,
      },
    });
  };

  return (
    <Box mt={2}>
      <TextField
        label="Poll Question"
        name="question"
        value={block.pollContent?.question || ''}
        onChange={handleChange}
        fullWidth
      />
      {block.pollContent?.options?.map((option, index) => (
        <Box key={option.id} mt={2}>
          <TextField
            label={`Option ${index + 1}`}
            value={option.text}
            onChange={(e) =>
              updateOption(index, { ...option, text: e.target.value })
            }
            fullWidth
          />
        </Box>
      ))}
      <Button variant="outlined" onClick={addOption} sx={{ mt: 2 }}>
        Add Option
      </Button>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Poll Type</InputLabel>
        <Select
          name="pollType"
          value={block.pollContent?.pollType || ''}
          label="Poll Type"
          onChange={handleChange}
        >
          <MenuItem value="SINGLE_CHOICE">Single Choice</MenuItem>
          <MenuItem value="MULTIPLE_CHOICE">Multiple Choice</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Presentation Style</InputLabel>
        <Select
          name="presentationStyle"
          value={block.pollContent?.presentationStyle || ''}
          label="Presentation Style"
          onChange={handleChange}
        >
          <MenuItem value="BAR_CHART">Bar Chart</MenuItem>
          <MenuItem value="PIE_CHART">Pie Chart</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Expiration Date"
        name="expiresAt"
        type="datetime-local"
        value={block.pollContent?.expiresAt || ''}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default PollBlock;
