import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Checkbox,
  FormControlLabel,
  Button,
  Box
} from '@mui/material';

const FormField = ({ name, label, value, isEditable, handleChange, type = 'text', options = [], helperText = '' }) => {
  switch (type) {
    case 'text':
    case 'email':
    case 'number':
    case 'password':
    case 'date':
      return (
        <TextField
          fullWidth
          label={label}
          name={name}
          type={type}
          value={value || ''}
          onChange={handleChange}
          InputProps={{
            readOnly: !isEditable,  // Make field read-only if not editable
          }}
          disabled={!isEditable} // Grey out if not editable
          helperText={helperText}
        />
      );

    case 'select':
      return (
        <FormControl fullWidth disabled={!isEditable}>
          <InputLabel>{label}</InputLabel>
          <Select
            name={name}
            value={value || ''}
            onChange={handleChange}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case 'file':
      return (
        <Box>
          <Button
            variant="contained"
            component="label"
            disabled={!isEditable}
          >
            {label}
            <input
              type="file"
              hidden
              name={name}
              onChange={handleChange}
            />
          </Button>
          {helperText && <Box sx={{ mt: 1, color: 'grey' }}>{helperText}</Box>}
        </Box>
      );

    case 'checkbox':
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(value)}
              onChange={handleChange}
              name={name}
              disabled={!isEditable}
            />
          }
          label={label}
        />
      );

    default:
      return (
        <TextField
          fullWidth
          label={label}
          name={name}
          type="text"
          value={value || ''}
          onChange={handleChange}
          InputProps={{
            readOnly: !isEditable,
          }}
          disabled={!isEditable}
        />
      );
  }
};

export default FormField;
