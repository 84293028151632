import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const SummaryCard = ({ icon, title, value, color }) => (
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: color,
      height: '100%',
      borderRadius: '12px',
      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
    }}
  >
    <Box sx={{ color: '#ffffff', fontSize: '2rem' }}>{icon}</Box> {/* Set icon color and size */}
    <Box sx={{ ml: 2 }}>
      <Typography
        variant="h5"
        sx={{ fontWeight: 'bold', color: '#ffffff' }}
      >
        {value}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#ffffff' }}
      >
        {title}
      </Typography>
    </Box>
  </Paper>
);

export default SummaryCard;
