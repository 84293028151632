import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, useMediaQuery, useTheme } from '@mui/material';

const AcademicYearStats = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data) return null;

  return (
    <Paper sx={{ width: '100%', overflow: 'auto' }}>
      <Table stickyHeader aria-label="academic year statistics table">
        <TableHead>
          <TableRow>
            <TableCell>Academic Year</TableCell>
            <TableCell align="right">Total Students</TableCell>
            {!isSmallScreen && (
              <>
                <TableCell align="right">Passed</TableCell>
                <TableCell align="right">Backlogs</TableCell>
              </>
            )}
            <TableCell align="right">Dropped</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.year}>
              <TableCell component="th" scope="row">
                {row.year}
              </TableCell>
              <TableCell align="right">{row.total}</TableCell>
              {!isSmallScreen && (
                <>
                  <TableCell align="right">{row.passed}</TableCell>
                  <TableCell align="right">{row.backlog}</TableCell>
                </>
              )}
              <TableCell align="right">{row.dropped}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default AcademicYearStats;