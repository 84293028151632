import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, CircularProgress , useTheme, useMediaQuery  } from '@mui/material';
import { LocationOn, School } from '@mui/icons-material';
import AcademicYearStats from '../components/dashboard/AcademicYearStats';
import FinanceInfo from '../components/dashboard/FinanceInfo';
import ParticipationData from '../components/dashboard/ParticipationData';
import SummaryCard from './../components/dashboard/SummaryCard';

// In a real application, these would be actual API calls
const fetchAcademicData = () => new Promise(resolve => setTimeout(() => resolve([
  { year: '1st Year', total: 500, passed: 480, backlog: 12, dropped: 8 },
  { year: '2nd Year', total: 464, passed: 458, backlog: 4, dropped: 2 },
  { year: '3rd Year', total: 532, passed: 523, backlog: 3, dropped: 6 },
  { year: '4th Year', total: 489, passed: 480, backlog: 5, dropped: 4 },
  { year: '5th Year', total: 497, passed: 478, backlog: 12, dropped: 7 },
]), 1000));

const fetchFinanceData = () => new Promise(resolve => setTimeout(() => resolve([
  { category: 'Entrance Fees', total: 500, availed: 320, amount: 384000 },
  { category: 'Institution Fees', total: 464, availed: 358, amount: 429600 },
  { category: 'Hostel Fees', total: 532, availed: 413, amount: 495600 },
  { category: 'Mess Fees', total: 489, availed: 382, amount: 458400 },
  { category: 'Other Fees', total: 497, availed: 328, amount: 393600 },
]), 1000));

const fetchParticipationData = () => new Promise(resolve => setTimeout(() => resolve({
  totalStudents: { boys: 80, girls: 250, transgender: 60 },
  visitedStudents: { boys: 60, girls: 190, transgender: 0 }
}), 1000));

const Dashboard = () => {
  const [academicData, setAcademicData] = useState(null);
  const [financeData, setFinanceData] = useState(null);
  const [participationData, setParticipationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [academic, finance, participation] = await Promise.all([
          fetchAcademicData(),
          fetchFinanceData(),
          fetchParticipationData()
        ]);
        setAcademicData(academic);
        setFinanceData(finance);
        setParticipationData(participation);
      } catch (err) {
        setError('Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Model School Management System
      </Typography>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard icon={<LocationOn />} title="Countries" value="10" color="#673ab7" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard icon={<LocationOn />} title="States" value="18" color="#3f51b5" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard icon={<LocationOn />} title="Cities" value="32" color="#e91e63" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard icon={<School />} title="Institutes" value="452" color="#ff9800" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={isSmallScreen ? 12 : 8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Finance Information
            </Typography>
            <FinanceInfo data={financeData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={isSmallScreen ? 12 : 4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Students
            </Typography>
            <ParticipationData data={participationData} chartType="pie" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={isSmallScreen ? 12 : 4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Participation
            </Typography>
            <ParticipationData data={participationData} chartType="donut" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={isSmallScreen ? 12 : 8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Academic Year Statistics
            </Typography>
            <AcademicYearStats data={academicData} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;