import React from 'react';
import { Typography, Divider, Box } from '@mui/material';

// This is the Section Header component, with custom styling
const SectionHeader = ({ title }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
    <Typography
      variant="h6"
      sx={{ color: '#4a148c',   mr: 2 }}
    >
      {title}
    </Typography>
    <Divider sx={{ flexGrow: 1, borderBottom: '1px solid #4a148c' }} />
  </Box>
);

export default SectionHeader;
