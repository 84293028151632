import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPost } from "../services/postService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "../components/ContentBlocks/ContentBlock";


import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";


const CreatePost = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [files, setFiles] = useState({});
  const [postData, setPostData] = useState({
    userId: user.userId,
    authorId: user.userId,
    authorName: user.name,
    authorRole: user.role,
    schoolId: user.schoolId,
    title: "",
    summary: "",
    contentType: "EVENT",
    contentBlocks: [],
    categories: [],
    tags: [],
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPostData((prev) => ({ ...prev, [name]: value }));
  };

  const handleArrayInputChange = (e) => {
    const { name, value } = e.target;
    setPostData((prev) => ({
      ...prev,
      [name]: value.split(",").map((item) => item.trim()),
    }));
  };

  const handleFileChange = (blockIdentifier, newFiles) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [blockIdentifier]: newFiles,
    }));
  };

  const updateContentBlock = (index, updatedBlock) => {
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= postData.contentBlocks.length) return;
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Text",
          order: prev.contentBlocks.length + 1,
          textContent: { text: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    let submissionData = { ...postData };

    if (submissionData.categories.length === 0) {
      delete submissionData.categories;
    } else {
      submissionData.categories = submissionData.categories.map((category) =>
        category.startsWith("CATEGORY#") ? category : `CATEGORY#${category}`
      );
    }

    if (submissionData.tags.length === 0) {
      delete submissionData.tags;
    }

    const formData = new FormData();
    formData.append("metadata", JSON.stringify(submissionData));

    Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
      blockFiles.forEach((file, index) => {
        formData.append(
          `file`,
          file,
          `file_${blockIdentifier}_${index}_${file.name}`
        );
      });
    });

    try {
      await createPost(formData);
      navigate("/");
    } catch (err) {
      setError("Failed to create post. Please try again.");
      console.error("Create post error:", err);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Create New Post
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Title"
            name="title"
            value={postData.title}
            onChange={handleInputChange}
            required
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Summary"
            name="summary"
            value={postData.summary}
            onChange={handleInputChange}
            required
            multiline
            rows={4}
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Content Type</InputLabel>
            <Select
              name="contentType"
              value={postData.contentType}
              label="Content Type"
              onChange={handleInputChange}
            >
              <MenuItem value="EVENT">Event</MenuItem>
              <MenuItem value="ANNOUNCEMENT">Announcement</MenuItem>
              <MenuItem value="NEWS">News</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Target Audience</InputLabel>
            <Select
              name="targetAudience"
              value={postData.targetAudience.split("#")[0]}
              label="Target Audience"
              onChange={(e) =>
                setPostData((prev) => ({
                  ...prev,
                  targetAudience: `${e.target.value}`,
                }))
              }
            >
              <MenuItem value="SCHOOL">School</MenuItem>
              <MenuItem value="DISTRICT">District</MenuItem>
              <MenuItem value="STATE">State</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Visibility Level</InputLabel>
            <Select
              name="visibilityLevel"
              value={postData.visibilityLevel.split("#")[0]}
              label="Visibility Level"
              onChange={(e) =>
                setPostData((prev) => ({
                  ...prev,
                  visibilityLevel: `${e.target.value}`,
                }))
              }
            >
              <MenuItem value="SCHOOL">School</MenuItem>
              <MenuItem value="DISTRICT">District</MenuItem>
              <MenuItem value="STATE">State</MenuItem>
              <MenuItem value="PUBLIC">Public</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Categories (comma-separated)"
            name="categories"
            value={
              postData.categories.length > 0
                ? postData.categories
                    .map((cat) => cat.replace("CATEGORY#", ""))
                    .join(", ")
                : ""
            }
            onChange={handleArrayInputChange}
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Tags (comma-separated)"
            name="tags"
            value={postData.tags.length > 0 ? postData.tags.join(", ") : ""}
            onChange={handleArrayInputChange}
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {postData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Create Post
        </Button>
      </form>
    </Container>
  );
};

export default CreatePost;