import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Paper, Typography, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import PersonalDetailsTab from './../components/userProfile/PersonalDetailsTab';
import EducationalDetailsTab from './../components/userProfile/EducationalDetailsTab';
import FinancialDetailsTab from './../components/userProfile/FinancialDetailsTab';
import { getStudentById, updateStudent } from '../services/studentService';

const UserProfile = ({ isEditable }) => {
  const [tabValue, setTabValue] = useState(0);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { emisNumber } = useParams();

  useEffect(() => {

    const fetchStudentData = async () => {
      try {
        setLoading(true);
        const data = await getStudentById(emisNumber);
        setProfile(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch student data');
        setLoading(false);
      }
    };

    if (emisNumber) {
      fetchStudentData();
    }
  }, [emisNumber]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: type === 'file' ? event.target.files[0] : value,
    }));
  };

  const handleSave = async (tabData) => {
    try {
      setLoading(true);
      await updateStudent(emisNumber, tabData);
      setLoading(false);
      // You might want to show a success message here
    } catch (err) {
      setError('Failed to update student data');
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!profile) return <Typography>No student data found</Typography>;

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {isEditable ? 'Edit Profile' : 'View Profile'}
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Personal Details" />
        <Tab label="Educational Details" />
        <Tab label="Financial Details" />
      </Tabs>

      <Paper sx={{ p: 3, mt: 2 }}>
        {tabValue === 0 && (
          <PersonalDetailsTab
            profile={profile}
            isEditable={isEditable}
            handleChange={handleChange}
            handleSave={handleSave}
          />
        )}
        {tabValue === 1 && (
          <EducationalDetailsTab
            profile={profile}
            isEditable={isEditable}
            handleChange={handleChange}
            handleSave={handleSave}
          />
        )}
        {tabValue === 2 && (
          <FinancialDetailsTab
            profile={profile}
            isEditable={isEditable}
            handleChange={handleChange}
            handleSave={handleSave}
          />
        )}
      </Paper>
    </Box>
  );
};

export default UserProfile;