import { Grid, Box } from '@mui/material';
import FormField from "../../FormField";
import SectionHeader from './../SectionHeader';

const GuardianDetails = ({ profile, isEditable, handleChange }) => {
    return (
      <Box>
        <SectionHeader title="Guardian Information" />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              name="guardianName"
              label="Guardian's Name"
              value={profile.guardianName}
              isEditable={isEditable}
              handleChange={handleChange}
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              name="guardianEducation"
              label="Guardian's Educational Qualification"
              value={profile.guardianEducation}
              isEditable={isEditable}
              handleChange={handleChange}
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              name="guardianOccupation"
              label="Guardian's Occupation"
              value={profile.guardianOccupation}
              isEditable={isEditable}
              handleChange={handleChange}
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              name="guardianIncome"
              label="Guardian's Annual Income"
              value={profile.guardianIncome}
              isEditable={isEditable}
              handleChange={handleChange}
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };
  export default GuardianDetails;