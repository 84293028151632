// VideoBlock.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, CardMedia } from '@mui/material';

const VideoBlock = ({ block, updateBlock, onFileChange }) => {
  const [preview, setPreview] = useState(block.videoContent?.thumbnailUrl || '');

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      videoContent: {
        ...block.videoContent,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange([file]);
      // You might want to generate a thumbnail here
      updateBlock({
        ...block,
        videoContent: {
          ...block.videoContent,
          url: '', // Will be set by server after upload
          thumbnailUrl: '', // Will be set by server after upload
          duration: '', // Will be set by server after upload
          caption: block.videoContent?.caption || '',
        },
      });
    }
  };

  return (
    <Box mt={2}>
      <Button variant="contained" component="label">
        Upload Video
        <input type="file" accept="video/*" hidden onChange={handleFileChange} />
      </Button>
      <TextField
        label="Video URL"
        name="url"
        value={block.videoContent?.url || ''}
        onChange={handleChange}
        placeholder="Video URL"
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Thumbnail URL"
        name="thumbnailUrl"
        value={block.videoContent?.thumbnailUrl || ''}
        onChange={handleChange}
        placeholder="Thumbnail URL"
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Duration"
        name="duration"
        value={block.videoContent?.duration || ''}
        onChange={handleChange}
        placeholder="Duration"
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Caption"
        name="caption"
        value={block.videoContent?.caption || ''}
        onChange={handleChange}
        placeholder="Caption"
        fullWidth
        sx={{ mt: 2 }}
      />
      {block.videoContent?.thumbnailUrl && (
        <Box mt={2}>
          <Typography variant="subtitle1">Thumbnail Preview:</Typography>
          <CardMedia
            component="img"
            image={block.videoContent.thumbnailUrl}
            alt="Thumbnail Preview"
            sx={{ maxWidth: 200 }}
          />
        </Box>
      )}
    </Box>
  );
};

export default VideoBlock;
