import React from 'react';
import { Grid, Box } from '@mui/material';
import SectionHeader from './../SectionHeader';
import FormField from "../../FormField";

const ParentalDetails = ({ profile, isEditable, handleChange }) => {
  return (
    <Box>
      <SectionHeader title="Parental Information" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormField
            name="father_name"
            label="Father's Name"
            value={profile.father_name}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="father_dob"
            label="Father's Date of Birth"
            value={profile.father_dob}
            isEditable={isEditable}
            handleChange={handleChange}
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="father_education"
            label="Father's Educational Qualification"
            value={profile.father_education}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="father_occupation"
            label="Father's Occupation"
            value={profile.father_occupation}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="father_annual_income"
            label="Father's Annual Income"
            value={profile.father_annual_income}
            isEditable={isEditable}
            handleChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="father_differently_abled"
            label="Father Differently Abled"
            value={profile.father_differently_abled}
            isEditable={isEditable}
            handleChange={handleChange}
            type="select"
            options={['Yes', 'No']}
          />
        </Grid>

        {/* Mother Details */}
        <Grid item xs={12} sm={4}>
          <FormField
            name="mother_name"
            label="Mother's Name"
            value={profile.mother_name}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="mother_dob"
            label="Mother's Date of Birth"
            value={profile.mother_dob}
            isEditable={isEditable}
            handleChange={handleChange}
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="mother_education"
            label="Mother's Educational Qualification"
            value={profile.mother_education}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="mother_occupation"
            label="Mother's Occupation"
            value={profile.mother_occupation}
            isEditable={isEditable}
            handleChange={handleChange}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="mother_annual_income"
            label="Mother's Annual Income"
            value={profile.mother_annual_income}
            isEditable={isEditable}
            handleChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            name="mother_differently_abled"
            label="Mother Differently Abled"
            value={profile.mother_differently_abled}
            isEditable={isEditable}
            handleChange={handleChange}
            type="select"
            options={['Yes', 'No']}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ParentalDetails;