import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, useMediaQuery, useTheme } from '@mui/material';

const FinanceInfo = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data) return null;

  return (
    <Paper sx={{ width: '100%', overflow: 'auto' }}>
      <Table stickyHeader aria-label="finance information table">
        <TableHead>
          <TableRow>
            <TableCell>Category of Fees</TableCell>
            {!isSmallScreen && (
              <>
                <TableCell align="right">Total No. of Student</TableCell>
                <TableCell align="right">Total No. of Students Availed</TableCell>
              </>
            )}
            <TableCell align="right">Total Amount Paid</TableCell>
            <TableCell align="right">Overall Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.category}>
              <TableCell component="th" scope="row">
                {row.category}
              </TableCell>
              {!isSmallScreen && (
                <>
                  <TableCell align="right">{row.total}</TableCell>
                  <TableCell align="right">{row.availed}</TableCell>
                </>
              )}
              <TableCell align="right">{row.amount}</TableCell>
              <TableCell align="right">{row.overallAmount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FinanceInfo;