// ImageBlock.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CardMedia,
} from '@mui/material';

const ImageBlock = ({ block, updateBlock, onFileChange }) => {
  const [preview, setPreview] = useState(block.imageContent?.url || null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      imageContent: {
        ...block.imageContent,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange([file]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      updateBlock({
        ...block,
        imageContent: {
          ...block.imageContent,
          url: '', // Will be set by server after upload
          caption: block.imageContent?.caption || '',
        },
      });
    }
  };

  return (
    <Box mt={2}>
      <Button variant="contained" component="label">
        Upload Image
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      <TextField
        name="url"
        label="Image URL"
        value={block.imageContent?.url || ''}
        onChange={handleChange}
        placeholder="Image URL"
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        name="caption"
        label="Image Caption"
        value={block.imageContent?.caption || ''}
        onChange={handleChange}
        placeholder="Image Caption"
        fullWidth
        sx={{ mt: 2 }}
      />
      {(preview || block.imageContent?.url) && (
        <Box mt={2}>
          <Typography variant="subtitle1">Preview:</Typography>
          <CardMedia
            component="img"
            image={preview || block.imageContent.url}
            alt="Preview"
            sx={{ maxWidth: 200 }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageBlock;
